import '../pages/Main.css';
import '../pages/Members.css';
import Profile from '../components/Profile';

// Make pictures exactly 1000x1000 pixels!
// Exec
import rucha from '../images/members2/rucha.jpg';
import isha from '../images/members/isha_sinha.jpg';
import caleb from '../images/members/caleb_shih.jpg';
import amy from '../images/members2/amy.jpg';
import aarthi from '../images/members2/aarthi.jpg';
import lauren from '../images/members2/laurenc.jpg';
import lana from '../images/members2/lana.jpg';
// PMs
import anika from '../images/members2/anika.jpg'
import vy from '../images/members2/vy.jpg';
import william from '../images/members2/will.jpg';
import xiaojian from '../images/members2/xiao.jpg';
import laurena from '../images/members2/laurena.jpg';
import kaif from '../images/members2/kaif.jpg';
import shazad from '../images/members2/shaz.jpg';
import sidharth from '../images/members2/sida.jpg';
import amit from '../images/members2/amit.jpg';
import manu from '../images/members2/manu.jpg';
import scott from '../images/members2/scott.jpg';





// Directors
import mallika from '../images/members/mallika_reddy.jpg';
import sreeanvitha from '../images/members2/anvi.jpg';
import shaan from '../images/members2/shaan.jpg';
import ananth from '../images/members/ananth_goyal.jpg';
import megan from '../images/members2/megan.jpg';
import stella from '../images/members2/stella.jpg';



// Analysts 
import ashwarye from '../images/members2/ash.jpg';
import andrew from '../images/members2/andrew.jpg';
import audrey from '../images/members2/audrey.jpg';
import pallavi from '../images/members2/pallavi.jpg';
import raniya from '../images/members2/raniya.jpg';
import manav from '../images/members2/manav.jpg';
import shailee from '../images/members2/shailee.jpg';
import kimberly from '../images/members2/kimberly.jpg';
import hiral from '../images/members2/hiral.jpg';



// Advisors
// import aiza from '../images/members/aiza_gill.jpg';
import andres from '../images/members/andres_lam.jpg';
import esha from '../images/members/esha_gautam.jpg';
import harshil from '../images/members/harshil_ganesha_murthy.jpg';
import isabella from '../images/members/isabella_pang.jpg';
// import juliette from '../images/members/juliette_clark.jpg';
import kareena from '../images/members/kareena_hargunani.jpg';
// import megha from '../images/members/megha_sreekanth.jpg';
import raksha from '../images/members/raksha_sen.jpg';
// import shanta from '../images/members/shanta_kurapati.jpg';
import timothy from '../images/members/timothy_yang.jpg';
import spruha from '../images/members/spruha_kar.jpg';
import catherine from '../images/members/catherine_lee.jpg';
import lkhagva from '../images/members/lkhagva_damdinbazar.jpg';
import allyson from '../images/members/allyson_seong.jpg'
import siddharth from '../images/members/siddharth_manchkanti.jpg';
import aryan from '../images/members/aryan_govil.jpg';
import katherine from '../images/members/katherine_gerhardt.jpg';
import nithika from '../images/members/nithika_valluri.jpg';
//import jared from '../images/members/jared_severns.jpg';
//import jessy from '../images/members/jessy_lu.jpg';

import allysonh from '../images/members2/allysonh.jpg';
import emma from '../images/members2/emma.jpg';
import ava from '../images/members2/ava.jpg';
import ece from '../images/members2/ece.jpg';

import ash from '../images/members2/ash.jpg';
import estefania from '../images/members2/estefania.jpg';
import kelley from '../images/members2/kelley.jpg';
import kinton from '../images/members2/kinton.jpg';
import mahit from '../images/members2/mahit.jpg';
import sebastian from '../images/members2/sebastian.jpg';
import shreya from '../images/members2/shreya.jpg';
import tanay from '../images/members2/tanay.jpg';







export default function Members() {
    return(
        <div>
			<div className="odd">
				<h1>Executive Board</h1>
				<div className="members">
					<Profile image={aarthi} name="Aarthi Kannan" linkedin="https://www.linkedin.com/in/aarthikannan526/"title="President"/>
					<Profile image={william} name="William Stern" linkedin="https://www.linkedin.com/in/william-stern04/" title="External VP"/>
					<Profile image={nithika} name="Nikki Valluri" linkedin="https://www.linkedin.com/in/nithikav/" title="Internal VP"/>
					<Profile image={amy} name="Amy Palm" linkedin="https://www.linkedin.com/in/amypalmoc/" title="Internal VP"/>
					<Profile image={lauren} name="Lauren Chu" linkedin="https://www.linkedin.com/in/laurenbchu/"title="Consulting VP"/>
					<Profile image={hiral} name="Hiral Patel" linkedin="https://www.linkedin.com/in/hiral-patel-b87512244/"title="Consulting VP"/>
					<Profile image={shaan} name="Shaan Pathak" linkedin="https://www.linkedin.com/in/shaanpathak/"title="Development VP"/>
					<Profile image={ananth} name="Ananth Goyal" linkedin="" title="Marketing VP"/>

					
				</div>
			</div>
			<div className="even">
				<h1>Project Managers</h1>
				<div className="members">
					<Profile image={andrew} name="Andrew Lin" linkedin="https://www.linkedin.com/in/andrew-lin-sd/" title="Project Manager"/>
					<Profile image={ash} name="Ashwarye Yadav" linkedin="https://www.linkedin.com/in/ashwaryeyadav/" title="Project Manager"/>
					<Profile image={audrey} name="Audrey Lim" linkedin="https://www.linkedin.com/in/audrey-jimin-lim/" title="Project Manager"/>
					<Profile image={laurena} name ="Lauren Angelus" linkedin="https://www.linkedin.com/in/laurenangelus/" title="Project Manager"/>
					<Profile image={manav} name="Manav Bhargava" linkedin="https://www.linkedin.com/in/manavbhargava7/" title="Project Manager"/>
					<Profile image={pallavi} name="Pallavi Joshi" linkedin="https://www.linkedin.com/in/pallavi-joshi1/" title="Project Manager"/>
					<Profile image={raniya} name="Raniya Khan" linkedin="https://www.linkedin.com/in/raniyakhan/" title="Project Manager"/>
					<Profile image={scott} name="Scott Su" linkedin="https://www.linkedin.com/in/scottsu-/" title="Project Manager"/>
					<Profile image={shailee} name="Shailee Nanavati" linkedin="https://www.linkedin.com/in/vylyy/" title="Project Manager"/>
					<Profile image={shazad} name="Shazad Khan" linkedin="https://www.linkedin.com/in/shazad-khan-b82171147/" title="Project Manager"/>
				</div>
			</div>
			<div className="odd">
				<h1>Directors</h1>
				<div className="members">
					<Profile image={kimberly} name="Kimberly Dawud" linkedin="https://www.linkedin.com/in/kimberlydawud/" title="DEI Director"/>
					<Profile image={allysonh} name="Allyson Hsu" linkedin="https://www.linkedin.com/in/allisonlhsu/" title="Events Director"/>
					<Profile image={megan} name="Megan Wang" linkedin="https://www.linkedin.com/in/megan-wang-685083250/" title="Events Director"/>
					<Profile image={anika} name="Anika Chandwani" linkedin="https://www.linkedin.com/in/anika-chandwani/" title="Development Director"/>
					<Profile image={kaif} name="Kaif Jeelani" linkedin="https://www.linkedin.com/in/kjeelani/" title="Development Director"/>
					<Profile image={sidharth} name="Sidharth Attam" linkedin="https://www.linkedin.com/in/sidharthattam/" title="Education Director"/>
					<Profile image={vy} name="Vy Ly" linkedin="https://www.linkedin.com/in/vylyy/" title="Education Director"/>
				</div>
			</div>
			<div className="even">
				<h1>Analysts</h1>
				<div className="members">
					<Profile image={allysonh} name="Allyson Hsu" linkedin="https://www.linkedin.com/in/allisonlhsu/" title="Senior Analyst"/>
					<Profile image={ava} name="Ava Shah" linkedin="https://www.linkedin.com/in/ava-shah-03709773/" title="Senior Analyst"/>
					<Profile image={ece} name="Ece Tabag" linkedin="https://www.linkedin.com/in/ecetabag/" title="Senior Analyst"/>
					<Profile image={kimberly} name="Kimberly Dawud" linkedin="https://www.linkedin.com/in/kimberlydawud/" title="Senior Analyst"/>
					<Profile image={emma} name="Emma Martinez" linkedin="https://www.linkedin.com/in/emma-martinez-749528225/" title="Senior Analyst"/>
					<Profile image={estefania} name="Estefania Aphang" linkedin="https://www.linkedin.com/in/estefanía-aphang-671174294/" title="Senior Analyst"/>
					<Profile image={kelley} name="Kelley Liang" linkedin="https://www.linkedin.com/in/kelley-l-993937222/" title="Senior Analyst"/>
					<Profile image={kinton} name="Kinton Duong" linkedin="https://www.linkedin.com/in/kintonduong/" title="Senior Analyst"/>
					<Profile image={mahit} name="Mahit Namburu" linkedin="https://www.linkedin.com/in/mahitn/" title="Senior Analyst"/>
					<Profile image={sebastian} name="Sebastian Nunez" linkedin="https://www.linkedin.com/in/sebastian-nunez701/" title="Senior Analyst"/>
					<Profile image={shreya} name="Shreya Dinesh" linkedin="https://www.linkedin.com/in/shreya-dinesh/" title="Senior Analyst"/>
					<Profile image={tanay} name="Tanay Joshi" linkedin="https://www.linkedin.com/in/tanay-joshi-tanayjoshi/" title="Senior Analyst"/>
				</div>
			</div>
			<div className="odd">
				<h1>Advisors</h1>
				<div className="members">
					{/* <Profile image={aiza} name="Aiza Gill" linkedin="https://www.linkedin.com/in/aizagill/" title="Senior Advisor"/> */}
					<Profile image={caleb} name="Caleb Shih" linkedin="https://www.linkedin.com/in/caleb-shih-4b1506233/" title="Senior Advisor"/>
					<Profile image={lana} name="Lana Sabet" linkedin="https://www.linkedin.com/in/lanasabet/" title="Senior Advisor"/>
					<Profile image={isha} name="Isha Sinha" linkedin="https://www.linkedin.com/in/isha-sinha12/" title="Senior Advisor"/>
					<Profile image={rucha} name="Rucha Mehendale" linkedin="https://www.linkedin.com/in/rucha-mehendale/" title="Senior Advisor"/>
					<Profile image={sreeanvitha} name="Anvi Emani" linkedin="https://www.linkedin.com/in/sreeanvitha-emani-445258186/" title="Senior Advisor"/>
					<Profile image={mallika} name="Mallika Reddy" linkedin="https://www.linkedin.com/in/mallikareddy7/" title="Senior Advisor"/>
					<Profile image={stella} name="Stella Paz" linkedin="https://www.linkedin.com/in/stella-paz/" title="Senior Advisor"/>
					<Profile image={amit} name="Amit Metapalli" linkedin="https://www.linkedin.com/in/amit-mettapalli-a96b421b5/" title="Senior Advisor"/>
					<Profile image={xiaojian} name="Xiao Li" linkedin="https://www.linkedin.com/in/xiaojianli/" title="Senior Advisor"/>

					{/*<Profile image={esha} name="Esha Gautam" linkedin="https://www.linkedin.com/in/eshagautam/" title="Senior Advisor"/>*/}
					{/*<Profile image={harshil} name="Harshil Ganesha" suffix="Murthy" linkedin="https://www.linkedin.com/in/harshilgm/" title="Senior Advisor"/>*/}
					{/*<Profile image={isabella} name="Isabella Pang" linkedin="https://www.linkedin.com/in/isabella-pang/" title="Senior Advisor"/>*/}
					{/* <Profile image={juliette} name="Juliette Clark" linkedin="https://www.linkedin.com/in/juliette-clark/" title="Senior Advisor"/> */}
					{/* <Profile image={megha} name="Megha Sreekanth" linkedin="https://www.linkedin.com/in/megha-sreekanth/" title="Senior Advisor"/> */}
					{/*	<Profile image={kareena} name="Kareena Hargunani" linkedin="https://www.linkedin.com/in/kareena-hargunani/" title="Senior Advisor"/>
					<Profile image={raksha} name="Raksha Sen" linkedin="https://www.linkedin.com/in/raksha-sen/" title="Senior Advisor"/>*?}
					{/* <Profile image={shanta} name="Shanta Kurapati" linkedin="https://www.linkedin.com/in/shantakurapati/" title="Senior Advisor"/> */}
					{/*<Profile image={timothy} name="Timothy Yang" linkedin="https://www.linkedin.com/in/timoteayang/" title="Senior Advisor"/>*/}
					<Profile image={allyson} name="Allyson Seong" linkedin="https://www.linkedin.com/in/allysonseong/" title="Senior Advisor"/>
					<Profile image={siddharth} name="Siddharth Manchkanti" linkedin="https://www.linkedin.com/in/sidmanchkanti/" title="Senior Advisor"/>
					<Profile image={aryan} name="Aryan Govil" linkedin="https://www.linkedin.com/in/aryan-govil/" title="Senior Advisor"/>
					<Profile image={katherine} name="Katherine Gerhardt" linkedin="https://www.linkedin.com/in/katherinegerhardt/" title="Senior Advisor"/>
					
					{/*<Profile image={catherine} name="Catherine Lee" linkedin="https://www.linkedin.com/in/catherineleepdx/" title="Senior Advisor"/>
					<Profile image={lkhagva} name="Lkhagva Damdinbazar" linkedin="https://www.linkedin.com/in/lkhagva-damdinbazar/" title="Senior Advisor"/>
					<Profile image={spruha} name="Spruha Kar" linkedin="https://www.linkedin.com/in/spruhakar/" title="Senior Advisor"/>

	<Profile image={nithika} name="Nithika Valluri" linkedin="https://www.linkedin.com/in/nithikav/" title="Senior Advisor"/>*/}

					








					



					
					


				</div>
			</div>
        </div>
    )
}
